<script lang="ts">
  import SitesRecommender from './site-recommender.svelte';

  import { personalizedSitesList } from '$stores/sites-store.js';
  import {
    setLocalStorageItem,
    getLocalStorageItem,
  } from '$utils/local-storage';

  export let uuid: string | null;
  export let selectedSites: string[] = [];
  export let primarySite: string;

  let dismissedSiteRecommender = Boolean(
    getLocalStorageItem('dismissedSiteRecommender')
  );
  let usedSiteRecommender = false;

  function onClose() {
    setLocalStorageItem('dismissedSiteRecommender', 'true');
    dismissedSiteRecommender = true;
  }

  $: visible =
    !dismissedSiteRecommender &&
    (selectedSites?.length <= 1 || usedSiteRecommender);
</script>

{#if visible}
  {#await personalizedSitesList(uuid || '') then sites}
    {@const suggestedPublications = sites.filter(
      (site) =>
        site.siteKey !== primarySite &&
        !!site.rank &&
        !selectedSites?.includes(site.siteKey)
    )}
    <SitesRecommender
      bind:usedSiteRecommender
      {suggestedPublications}
      on:update
      on:close={onClose}
    />
  {/await}
{/if}
