<script lang="ts">
  import { css } from '@amedia/brick-tokens';
  import { afterUpdate, createEventDispatcher } from 'svelte';

  import Home from './home.svelte';
  import Explore from './explore.svelte';

  import Button from '$components/style/button/button.svelte';
  import type {
    DomainKeys,
    SitesDomains,
    SitesNames,
  } from '$stores/sites-store';
  import { type UserStore, user } from '$stores/user-store';
  import { ALL } from '$utils/consts';
  import { Locations } from '$stores/router-store';
  import i18n from '$i18n/i18n';
  import Icon from '$components/style/icon/icon.svelte';
  import {
    setLocalStorageItem,
    getLocalStorageItem,
  } from '$utils/local-storage';

  export let userData: Exclude<UserStore['data'], null>;
  export let primarySite: string;
  export let sitesDomains: SitesDomains;
  export let domainKeys: DomainKeys;
  export let sitesNames: SitesNames;
  export let innerWidth: number;
  export let filterOptions: { [key: string]: string } = {};
  export let location: Locations;

  const { uuid, trackingKey } = userData;

  let dialogComponent: HTMLDialogElement;
  let filter: string = ALL;

  const headerStyle = css({
    margin: '$x12 auto $x4',
    paddingInline: '1rem',
    display: 'flex',

    '@media screen and (min-width: 768px)': {
      paddingInline: '2rem',
    },

    variants: {
      showHome: {
        false: {
          margin: '$x12 auto $x8',
        },
      },
    },
  });

  const headerButtonStyle = css({
    marginTop: 'auto',
    flex: 1,
    '& .mozart-button': {
      '& > span': {
        padding: '$x2 $x3',
        height: '24px',
      },
    },

    variants: {
      popular: {
        true: {
          display: 'flex',
          alignItems: 'center',
        },
      },
    },
  });

  const verticalWrapperStyle = css({
    marginBottom: '$x10',
  });

  const logoContainer = css({
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 8px)',
    width: 'min-content',
    paddingRight: '$x4',

    variants: {
      tripleLogo: {
        true: { gridTemplateColumns: 'repeat(3, 8px)' },
      },
    },
  });

  const itemImageStyle = css({
    width: '24px',
    height: '24px',
    borderRadius: '$avatar',
    display: 'inline-block',
    boxSizing: 'border-box',
    background: 'white',
    backgroundPosition: 'center',
    backgroundSize: 'contain',

    variants: {
      selected: {
        true: {
          border: '1px solid $buttonPrimaryActiveBg',
        },
        false: {
          border: '1px solid $buttonPrimaryBg',
        },
      },
    },
  });

  const notifClass = css({
    background: '$buttonPrimaryBg',
    backgroundColor: '$buttonSignalBg',
    borderRadius: '9999px',
    fontSize: '$baseMetaXs',
    padding: '$x1',
    fontFamily: '$baseMetaXs',
    fontWeight: 'bold',
  });

  let mainElement: HTMLElement | undefined;
  let adType: 'netboard' | 'midtbanner' | 'unknown' = 'unknown';

  const dispatch = createEventDispatcher();

  $: filterOptions =
    $user.data?.preferredSites?.reduce(
      (acc, { siteKey }) => ({ ...acc, [siteKey]: sitesNames[siteKey] }),
      {}
    ) || {};

  $: showHome = location === Locations.Home;

  $: sortedOptions = Object.entries(filterOptions).sort((a, b) =>
    a[1].localeCompare(b[1], 'nb')
  );

  if (getLocalStorageItem('popular-pressed') === null) {
    setLocalStorageItem('popular-pressed', 'false');
  }

  afterUpdate(() => {
    if (mainElement) {
      adType = mainElement.clientWidth > 580 ? 'netboard' : 'midtbanner';
    }
  });
</script>

<header class={headerStyle({ showHome: showHome })}>
  <div class={headerButtonStyle()}>
    <Button
      clickLabel="frontpage-navigation"
      clickValue="navigate-home"
      text={sortedOptions.length > 1
        ? $i18n.t('frontpage.title.mySites')
        : 'Min avis'}
      selected={showHome}
      fullWidth
      on:click={() => {
        if (showHome) {
          dialogComponent.showModal();
        } else {
          dispatch('change', { location: 'home' });
        }
      }}
    >
      <div
        class={logoContainer({
          tripleLogo: sortedOptions.slice(0, 3).length === 3,
        })}
      >
        {#each Array(sortedOptions.slice(0, 3).length) as _, index (index)}
          <div
            class={itemImageStyle({
              rightShift: index < sortedOptions.length - 1,
              selected: filter === ALL,
            })}
            style="z-index: {sortedOptions.length - index};
                    background-image: url(https://r.acdn.no/local/v3/publications/{sitesDomains[
              sortedOptions[index][0]
            ]}/gfx/square.svg)"
          />
        {/each}
      </div>
    </Button>
  </div>
  <div class={headerButtonStyle({ popular: true })}>
    <Button
      clickLabel="frontpage-navigation"
      clickValue="navigate-explore"
      selected={!showHome}
      fullWidth
      iconPosition="right"
      on:click={() => {
        setLocalStorageItem('popular-pressed', 'true');
        dispatch('change', { location: 'explore' });
      }}
    >
      <Icon icon="popular" />
      <span>{$i18n.t('frontpage.title.explore')}</span>
      {#if getLocalStorageItem('popular-pressed') === 'false'}
        <span class={notifClass()}>Ny</span>
      {/if}
    </Button>
  </div>
</header>

<main
  bind:this={mainElement}
  class={verticalWrapperStyle()}
  id="main-content-begin"
>
  {#if showHome}
    <Home
      {adType}
      {uuid}
      {trackingKey}
      {primarySite}
      {sitesDomains}
      {domainKeys}
      {innerWidth}
      {sortedOptions}
      {location}
      bind:filter
      bind:dialogComponent
    />
  {:else}
    <Explore {uuid} {trackingKey} {primarySite} {domainKeys} {adType} />
  {/if}
</main>
